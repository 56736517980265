.select {
  position: relative;
  user-select: none;
}

.placeholder {
  color: rgb(0, 0, 0, 0.2);
}

.selected {
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 6px;
  padding-right: 32px;
  position: relative;

  background: white;
  border: var(--border);
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
}

.valueError {
  border-color: red !important;
}

.error {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  font-size: 14px;
  color: red;
}

.selected, .option {
  position: relative;

  & > svg {
    height: 16px;
    margin-left: auto;

    transform: rotate(-90deg);
    transition: transform 0.2s linear;
  }
}

.selected > svg {
  position: absolute;
  top: 11px;
  right: 0;
}

.selected.showOptions, .option.showOptions {
  & > svg {
    transform: rotate(-90deg) scale(-1, 1);
  }
}

.selectedItem {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  margin: 1px 4px 1px 0;
  
  background: rgba(0, 0, 0, 0.05);
  border: var(--border);
  border-radius: 4px;

  font-size: 14px;
  white-space: nowrap;
  cursor: default;

  & > svg {
    width: 10px;
    height: 10px;
    margin-left: 10px;

    stroke: var(--color-text);
    stroke-width: 2;

    cursor: pointer;
  }
}

.options {
  width: 140px;
  display: none;

  position: absolute;
  top: 38px;
  // transform: translateY(100%);

  border-radius: 4px;
  background: white;
}

.select .right {
  right: 0;
}

.optionsOpen {
  width: 100%;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  z-index: 1;
  overflow: hidden;

  border: var(--border);
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.optionContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  font-size: 16px;
  cursor: pointer;
  text-align: left;

  & > p {
    color: rgb(63, 62, 62);
    background: white;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
  }

  &:hover {
    & > p {
      color: var(--color-primary);
    }
  }
}

.option {
  width: max-content;
  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 10px;
}

.optionChildren {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}