.container {
  width: 90%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-background);
}

.titleContainer {
  width: 100%;
  padding: 16px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid gray;

}

.ghostBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}

.linkContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 25px;

}

.linkInput {
  width: 0;
  flex: 1 0 0;
  padding: 0;
  border: none;
  background: none;
  cursor: default;
  font-size: 16px;
  margin-right: 20px;
}

.linkInput:focus {
	outline: none;
}