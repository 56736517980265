.container {
  display: flex;
  align-items: center;
}

.refusal,
.agreement {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  background: var(--color-green);
  cursor: pointer;
  border-radius: 50%;
  border: 0;
}

.refusal {
  background: var(--color-red);
  margin-left: 6px;
}