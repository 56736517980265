.btn {
  padding: 12px 16px;
  // margin-bottom: 12px;

  background: var(--color-primary);
  border: none;
  border-radius: 4px;

  font-size: 16px;
  line-height: 1;
  color: white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    filter: grayscale(0.6);
    cursor: default;
  }
}

.secondary {
  background: none;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}