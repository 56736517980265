.inputContainer {
  width: 200px;
}

.popConfirmContainer {
  right: 40px;
  bottom: 50px;
}

.popConfirmBtnContainer{
  display: flex;
  justify-content: space-between;
}

.popConfirmBtn{
  padding: 8px 10px;
}