.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: var(--product-background_card);
  border: var(--border);
  border-radius: 6px;
  // box-shadow: 0 0 12px var(--color-shade);
  cursor: pointer;
}
.expired{
  filter: blur(2px) grayscale(80%);

  &:hover{
  filter: grayscale(0%);
  .expiredText{
    color: rgb(233, 33, 33);
  }
  }
}

.expiredText{
  font-weight: bold;
  color: var(--color-primary_text);
  transition: color ease 0.2s;
}

.gallery {
  height: 240px !important;
  background: white;
}

.info {
  flex: 1 0 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 
  "n l"
  "p p"
  ;
  grid-column-gap: 16px;
  padding: 16px;
}

.like {
  width: 24px;
  height: 24px;
  grid-area: l;
  transition: opacity 0.2s;

  & > svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    stroke: white;
  }

  @media (min-width: 768px)  {
    opacity: 0;
  }
}

.liked {
  opacity: 1 !important;
  & > svg {
    fill: red;
    stroke: red;
  }
}

.container:hover .like {
  opacity: 1;
}

.price,
.name {
  margin-bottom: 8px;
  letter-spacing: 0.8px;
  color: var(--color-primary_text);
}

.name {
  grid-area: n;
}

.price {
  margin-top: auto;
  grid-area: p;
}

.leftArrow {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 5px;
  z-index: 1;
  filter: drop-shadow(0 0 0.75rem rgb(133, 132, 132));
}

.leftArrow :hover {
  width: 40px;
}

.rightArrow {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 15px;
  z-index: 1;
  filter: drop-shadow(0 0 0.75rem rgb(133, 132, 132));

  & > img {
    transform: rotate(180deg);
  }
}

.rightArrow:hover {
  width: 40px;
}
